<template>
  <div>
    <h3>Påstand:</h3>
    <h2 class="paastander">UiA har et behov for større fokus på mangfold.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              Agder har et rykte som en region som har for lite fokus på mangfold.
              Spesielt respekt for andre religioner og for kjønns- og seksualitetsmangfold kan være mangelfull i Agder. Synes du at man bør legge mer vekt på arbeid med mangfoldkompetanes på UiA for å motvirke dette?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>

</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "Spm1",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>